import { useEffect, useRef, useState } from "react";
//helper
import {
  PrimeItem,
  RunnerColCheckboxes,
  RunnerFieldDTO,
} from "../../Helpers/StaticData/race-data";
import {
  checkNull,
  formatNullToZero,
  getLocalStorageObject,
  getPoolKeyStr,
  getRaceKeyStr,
  getRaceTripKeyStr,
} from "../../Helpers/valueHelper";
import { LOCAL_STORAGE, ROUTES } from "../../Helpers/constants";
import { findIndex, sortRaceTrips } from "../../Helpers/list-helpers";
import { ColsDefaultRaceTrip, TableColumnsDTO } from "../../Helpers/columns";

//context
import {
  ScheduleContextDTO,
  ScheduleContextProvider,
} from "../../Context/scheduleContext";

import "./scheduleManagement.scss";
//models
import { RaceDetailsDTO, ResultsDTO } from "../../Models/RaceDetailsDTO";
import { RaceTripDTO } from "../../Models/raceTripsDTO";
import { UserDetailsDTO } from "../../Models/UserManagementDto";
import { ScratchProfileDTO } from "../../Models/scratchProfileDTO";
import { PayOffDTO, PoolDTO } from "../../Models/PoolsDTO";
import { CLOG, showAPIToast } from "../../Helpers/ui-helper";

//coponent
import Header from "../../UI-Components/Header/header";
import AddNewRaceComponent from "./AddNewRaceComponent/addNewRaceComponent";
import RaceSchedular from "../Components/RaceSchedularComponent/raceSchedular";
import DisplayTrackRaces from "../Components/DisplayTrackRaces/displayTrackRaces";
import LargeLoader from "../../UI-Components/Loader/loaders";
import PoolsComponent from "./PoolsComponent/poolsComponent";
import RaceTimeConfig from "./RaceTimeConfig/RaceTimeConfig";
import AddTrackPools from "./AddTrackPools/AddTrackPools";

//prime react
import { Tooltip } from "primereact/tooltip";

//service
import { RulesService } from "../../services/rulesApiService";
import { UserApiService } from "../../services/userApiService";
import { ScheduleApiService } from "../../services/scheduleApiService";

import AddTrackPoolForm from "./AddTrackPools/AddTrackPoolForm/addTrackPoolForm";
import { useHistory } from "react-router";
import SelectedRaceComponent from "../Components/SelectedRace/selectedRaceComponent";
import { ConnectorDTO } from "../../Models/temp";
import RaceTripComponent from "./RaceTripComponent/raceTripComponent";

export default function SchedulesManagement() {
  const history = useHistory();
  const [isNewRace, setIsNewRace] = useState((prev: any) => prev ?? false);
  const [isTracksAddPools, setTracksAddPools] = useState(
    (prev: any) => prev ?? false
  );

  const [sContext] = useState({} as ScheduleContextDTO);

  const [connectorOptions, setConnectorOptions] = useState([] as any);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [raceConnectorInfo, setRaceConnectorInfo] = useState<any>({});

  const [selectedRace, setselectedRace] = useState<any>({});
  const [selectedRaceTrips, setSelectedRaceTrips] = useState<RaceTripDTO[]>([]);
  const [selectedTrack, setSelectedTrack] = useState<any>();
  const [selectedRaceTime, setSelectedRaceTime] = useState<any>({});


  //new
  const [isSelectedTrackwisePools, setIsSelectedTrackwisePools] =
    useState(false);
  // defaults
  const [provinces, setProvinces] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [scrProfileList, setScrProfileList] = useState<PrimeItem[]>([]);
  const [usersList, setUserList] = useState<PrimeItem[]>([]);
  //FIXME

  const [pools, setPools] = useState<PoolDTO[]>([]);
  const [poolToEdit, setPoolToEdit] = useState<PoolDTO | null | undefined>();

  const [runnerFields, setRunnerFileds] = useState<any>();
  const [runnerFieldCols, setRunnerFiledsCols] = useState<TableColumnsDTO[]>(
    []
  );

  //onPools Open
  const [isPoolsOpen, setIsPoolsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [totalRaces, setTotalRaces] = useState<number>(0);
  const raceTrips = useRef<RaceTripDTO[]>([]);
  const poolsObj = useRef<any>();


  // send props
  const raceTimeProps = {
    setIsSidebarOpen,
    isSidebarOpen,
    selectedRaceTime,
  };

  useEffect(() => {
    const setInitialContext = () => {
      onSetConnectorOptions();
      setPoolToEdit({} as PoolDTO);
      let fields = getLocalStorageObject(LOCAL_STORAGE.SM_RUNNER_FIELDS);
      if (fields) {
        let runnerFields: RunnerFieldDTO[] = Object.values(fields);
        let cols: TableColumnsDTO[] = [];
        for (let field of runnerFields) {
          let tableCol: TableColumnsDTO = {
            field: field.key,
            header: field.label,
            width: " ",
          };
          if (field.isTrue && field.key !== "providerId") cols.push(tableCol);
        }
        setRunnerFileds(fields);
        setRunnerFiledsCols(cols);
      } else {
        setRunnerFileds(RunnerColCheckboxes);
        setRunnerFiledsCols(ColsDefaultRaceTrip);
      }
    };
    setInitialContext();
    return () => {
      setIsNewRace(false);
      setTracksAddPools(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSetConnectorOptions = () => {
    let options = [] as PrimeItem[];
    RulesService.getConnectors()
      .then((res) => {
        CLOG("=>___API___Connectors Options____ ", "purple");
        if (res.data) {
          let connectors = res.data as ConnectorDTO[];
          for (let connector of connectors) {
            options.push({
              label: connector.connectorName,
              value: connector.accountId,
              currency: connector.currency,
            });
          }
          setConnectorOptions(options);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get all provinces which used to add races
  const getAllProvinces = () => {
    startLoader();
    ScheduleApiService.getProvinces()
      .then((res) => {
        if (res.data) {
          let provinces = Object.values(res.data).map((p: any) => p.province);
          setProvinces([...provinces.sort()]);
        }
        stopLoader();
      })
      .catch((err) => {
        stopLoader();
        console.log(err);
      });
  };

  // calling while click on race
  const onRaceClick = (race: RaceDetailsDTO) => {
    setselectedRace({ ...race });
    let raceObj = {} as any;
    raceObj.pzoneCode = race.raceKey.pzoneCode;
    raceObj.date = race.raceKey.localDate;
    setSelectedRaceTime(raceObj);
    getPools(race);
    getRaceTrips(race);
    setPoolToEdit(null);
    setIsFormVisible(false);
    setTotalRaces(Object.values(selectedTrack.races).length);
  };

  const togglePoolForm = (state: boolean) => {
    setIsFormVisible(state);
    if (state) {
      getScrProfiles();
      getUserList();
    }
  };

  const startLoader = () => {
    setIsLoading(true);
  };

  const stopLoader = () => {
    setIsLoading(false);
  };

  // fetch race trips for selected race
  const getRaceTrips = (race: RaceDetailsDTO) => {
    let raceKeystr = getRaceKeyStr(race.raceKey);
    setSelectedRaceTrips([]);

    startLoader();
    ScheduleApiService.getRaceTrips(raceKeystr)
      .then((res) => {
        if (res.data) {
          let tempRaceTrips = res.data as RaceTripDTO[];
          for (let trip of tempRaceTrips) {
            trip.age = formatNullToZero(trip.age);
            trip.barrierPos = formatNullToZero(trip.barrierPos);
            trip.horseWeight = formatNullToZero(trip.horseWeight);
            trip.carriedWeight = formatNullToZero(trip.carriedWeight);
            trip.claimingPrice = formatNullToZero(trip.claimingPrice);
            trip.earnings = formatNullToZero(trip.earnings);
            trip.speedIndex = formatNullToZero(trip.speedIndex);
            trip.sex = checkNull(trip.sex);
            trip.breedType = checkNull(trip.breedType);
            trip.jockeyName = checkNull(trip.jockeyName);
            trip.horseName = checkNull(trip.horseName);
            trip.providerId = checkNull(trip.providerId);
            trip.apprenticeType = checkNull(trip.apprenticeType);
            trip.equipmentsType = checkNull(trip.equipmentsType);
            trip.medicationType = checkNull(trip.medicationType);
            trip.trainerName = checkNull(trip.trainerName);
            trip.programNo = trip.raceTripKey.selId;
            trip.raceTripKeyStr = getRaceTripKeyStr(trip.raceTripKey);
          }
          let rt = [...sortRaceTrips(tempRaceTrips)];
          setSelectedRaceTrips(rt);
          raceTrips.current = rt;

          setIsNewRace(false);
          setTracksAddPools(false);
          stopLoader();
        }
      })
      .catch((err) => {
        stopLoader();
        console.error(err);
        showAPIToast(err, "while fetching runner data", "saveTrip");
      });
  };

  // Fetch pools data for selected race
  const getPools = (race: RaceDetailsDTO) => {
    startLoader();
    let raceKeyStr = getRaceKeyStr(race.raceKey);
    ScheduleApiService.getRacePools(raceKeyStr)
      .then((res) => {
        if (res && res.data) {
          let pools = {} as any;
          for (let pool of res.data) {
            if (pool) {
              pool.poolDetails.poolKeyString = getPoolKeyStr(
                pool.poolDetails.poolKey
              );
              if (pool.poolDetails.payoff) {
                let keys = Object.keys(pool.poolDetails.payoff);

                pool.poolDetails.payOffList = [];
                for (let key of keys) {
                  let payObj = {} as PayOffDTO;
                  payObj.selId = key;
                  payObj.payOff = pool.poolDetails.payoff[key]?.toFixed(2) ?? 0;
                  pool.poolDetails.payOffList.push(payObj);
                }
              }
              pools[pool.poolDetails.poolKeyString] = pool;
            }
          }
          setPools(pools);
          poolsObj.current = pools;
        } else {
          poolsObj.current = [];
          setPools([]);
        }
        stopLoader();
      })
      .catch((err) => {
        console.error(err);
        stopLoader();
        showAPIToast(err, "while fetching race pools", "pool_fetch");
      });
  };

  const onNewAddClick = () => {
    getAllProvinces();
    setIsNewRace(!isNewRace);
    setTracksAddPools(false);
    setIsSelectedTrackwisePools(false);
  };

  const onTracksPoolAdd = () => {
    setTracksAddPools(!isTracksAddPools);
    setIsNewRace(false);
    getScrProfiles();
    getUserList();
    setIsSelectedTrackwisePools(false);
  };

  const onTrackPoolAdd = () => {
    getScrProfiles();
    getUserList();
    setIsSelectedTrackwisePools(true);
    setIsNewRace(false);
    setTracksAddPools(false);
  };

  const handleRaceTimeClick = () => {
    setIsSidebarOpen(true);
  };

  const getScrProfiles = () => {
    startLoader();
    RulesService.getScrProfiles()
      .then((res) => {
        CLOG("=> API____SCR PROFILES FETCHED____", "purple");
        if (res.data) {
          let profiles = res.data as ScratchProfileDTO[];
          let profileList: PrimeItem[] = [];
          for (let profile of profiles) {
            if (!profile.deleted) {
              profileList.push({
                label: profile.profileName,
                value: profile.scratchedProfileKey.scratchedProfileId,
              });
            }
          }
          setScrProfileList([...profileList]);
        }
        stopLoader();
      })
      .catch((err) => {
        console.error(err);
        stopLoader();
        showAPIToast(err, "while fetching Scratch Profiles");
      });
  };

  const getUserList = () => {
    startLoader();
    UserApiService.getAllUsers()
      .then((res) => {
        if (res.data) {
          let userDropList: PrimeItem[] = [];
          let users = res.data as UserDetailsDTO[];

          for (let u of users) {
            let prime: PrimeItem = { label: u.user.email, value: u.user.uid };
            userDropList.push(prime);
          }
          setUserList([...userDropList]);
        }
        stopLoader();
      })
      .catch((err) => {
        stopLoader();
        console.log(err.response);
        showAPIToast(err, "fetching user list", "user");
      });
  };

  const updatePoolDataFromSocket = (pool: PoolDTO, raceKeyStr: string) => {
    let pools = { ...poolsObj.current };
    pool.poolDetails.poolKeyString = getPoolKeyStr(pool.poolDetails.poolKey);
    if (pool.poolDetails.payoff) {
      let keys = Object.keys(pool.poolDetails.payoff);

      pool.poolDetails.payOffList = [];
      for (let key of keys) {
        let payObj = {} as PayOffDTO;
        payObj.selId = key;
        payObj.payOff = pool.poolDetails.payoff[key]?.toFixed(2) ?? 0;
        pool.poolDetails.payOffList.push(payObj);
      }
    }
    pools[pool.poolDetails.poolKeyString] = pool;
    poolsObj.current = pools;
    setPools(pools);
  };

  const updateRaceTripsFromSocket = (trip: RaceTripDTO) => {
    trip.raceTripKeyStr = getRaceTripKeyStr(trip.raceTripKey);
    trip.isEditing = false;

    let raceT = [...raceTrips.current];
    let index = findIndex(raceT, trip, "raceTripKeyStr");

    if (index !== -1) raceT[index] = trip;
    else raceT.push(trip);

    setSelectedRaceTrips([...raceT]);
    raceTrips.current = raceT;
  };

  const openRaceInSchedule = () => {
    localStorage.setItem(
      LOCAL_STORAGE.SELECTEDRACEKEY,
      JSON.stringify(selectedRace.raceKey)
    );
    history.push(ROUTES.FIXED_ODDS_MANAGEMENT);
  };

  const renderPages = () => {
    if (isNewRace && provinces)
      return (
        <AddNewRaceComponent toggleAddRace={(state) => setIsNewRace(state)} />
      );
    if (isTracksAddPools) {
      return <AddTrackPools setisAddPools={setTracksAddPools} />;
    }
    let _context = { ...sContext };
    if (isSelectedTrackwisePools) {
      return (
        <div className="selected-event-container ">
          <div className="details">
            <span className="trackName">{selectedTrack.countryType} - </span>
            &nbsp;&nbsp;
            <span className="trackName">{selectedTrack.trackName} - </span>
            &nbsp;&nbsp;
            <span className="trackName">{selectedTrack.eventType}</span>
          </div>
          <div>
            <AddTrackPoolForm
              trackwiseRaces={Object.values(selectedTrack.races)}
              selectedDate={_context.selectedDate}
              selectedTrackName={selectedTrack}
              setisAddPools={setIsSelectedTrackwisePools}
            />
          </div>
        </div>
      );
    }

    if (selectedRaceTrips?.length)
      return (
        <>
          <SelectedRaceComponent
            canModify
            race={selectedRace}
            setIsFormVisible={setIsFormVisible}
            setIsPoolsOpen={setIsPoolsOpen}
          />
          <RaceTripComponent />
        </>
      );
    if (selectedRaceTrips?.length === 0)
      return (
        <div className="pz-card">
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "2rem",
              minHeight: "50vh",
              alignItems: "center",
              color: "gray",
            }}
          >
            RaceTrip Data Not Available
          </span>
        </div>
      );
    return null;
  };

  
  return (
    <ScheduleContextProvider
      value={{
        ...sContext,

        connectorsOptions: connectorOptions,
        setConnectorOptions: setConnectorOptions,


      

        selectedRace: selectedRace,
        setSelectedRace: setselectedRace,

        selectedRaceTrips: selectedRaceTrips,
        setSelectedRaceTrips: setSelectedRaceTrips,

        racePoolsObj: pools,
        setPools: setPools,

        provinces: provinces,
        scrProfileList: scrProfileList,

        usersList: usersList,

        poolToEdit: poolToEdit,
        setPoolToEdit: setPoolToEdit,

        runnerFields: runnerFields,
        setRunnerFields: setRunnerFileds,

        runnerFieldCols: runnerFieldCols,
        setRunnerFiledsCols: setRunnerFiledsCols,

        togglePoolForm: togglePoolForm,
        startLoader: startLoader,
        stopLoader: stopLoader,
        totalRaces: totalRaces,
        
      
      }}
    >
      <div>
        <Header pageTitle="Schedule Management" />
        <LargeLoader isLoading={isLoading} />
        {!isNewRace && selectedRace?.foGenerated && (
          <>
            <Tooltip
              target=".open-btn-race"
              content="Open This Race in Fixed Odds Management"
              position="left"
            />
            <button className="open-btn-race" onClick={openRaceInSchedule}>
              <i className="fa fa-external-link"></i>
            </button>
          </>
        )}

        <div className="content heightfull">
          <div className="content-left-panel">
            <RaceSchedular
              updateRaceDetails={setselectedRace}
              updatePoolData={updatePoolDataFromSocket}
              updateRaceTrips={updateRaceTripsFromSocket}
              onRaceClick={onRaceClick}
              selectedRace={selectedRace}
              raceConnectorInfo={raceConnectorInfo}
              setRaceConnectorInfo={setRaceConnectorInfo}
              selectedTrack={selectedTrack}
              setSelectedTrack={setSelectedTrack}
              setIsSelectedTrackwisePools={setIsSelectedTrackwisePools}
            />
          </div>

          <div
            className="content-middle-panel w-100"
            style={{ paddingBottom: "6rem" }}
          >
            <div className="inpage-nav">
              <div className="page-nav">
                <button
                  className={isNewRace ? "nav-btn active-btn" : "nav-btn"}
                  onClick={onNewAddClick}
                >
                  <i className="fa fa-plus"></i> &nbsp; Add New Race
                </button>
                <button
                  className={
                    isTracksAddPools ? "nav-btn active-btn" : "nav-btn"
                  }
                  onClick={(state: any) => onTracksPoolAdd()}
                >
                  <i className="fa fa-plus"></i> &nbsp; Add Pools
                </button>
                <button
                  className={isSidebarOpen ? "nav-btn active-btn" : "nav-btn"}
                  onClick={handleRaceTimeClick}
                >
                  <i className="fa fa-clock-o"></i> &nbsp; Race Time
                </button>
              </div>
              {isSidebarOpen && <RaceTimeConfig {...raceTimeProps} />}
              {/* FIXME */}
              <PoolsComponent
                connectorInfo={raceConnectorInfo}
                isFormVisible={isFormVisible}
                isPoolsOpen={isPoolsOpen}
                setIsPoolsOpen={setIsPoolsOpen}
              />
            </div>

            <div className="selected-event-container p-1">
              {selectedTrack?.races && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <DisplayTrackRaces
                    onRaceClick={onRaceClick}
                    raceConnectorInfo={raceConnectorInfo}
                    selectedRaces={selectedTrack.races}
                    selectedRace={selectedRace}
                  />
                  <div className="wrapper">
                    <div
                      className="icon facebook"
                      onClick={() => onTrackPoolAdd()}
                    >
                      <div className="tooltip">Track Pools</div>
                      <span>
                        <i className="fa fa-plus"></i>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>{renderPages()}</div>
          </div>
        </div>
      </div>
    </ScheduleContextProvider>
  );
}
